/**
* Generated automatically at built-time (2025-01-31T10:39:29.830Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-abdeckung-outdoorkueche",templateKey: "sites/102-2ecafa94-0247-42de-9fbe-58efb95d5159"};