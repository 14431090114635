/**
* Generated automatically at built-time (2025-01-31T10:39:29.738Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-planung-outdoorkueche",templateKey: "sites/102-4d1929ee-0e9b-4952-a147-899ab4800c61"};